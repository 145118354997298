import { useAppSelector } from "../../app";
import { useMemo } from "react";
import {
  DocumentCategoryAnnotation,
  ICategorization,
} from "../../configMap/interfaces/category";
import selectDocumentCategoryAnnotationId from "../selectors/selectDocumentCategoryAnnotationId";
import selectCategorizations from "../selectors/selectCategorizations";
import { getInLanguageOrDefault } from "../../common/utilities/language";
import { useTranslation } from "react-i18next";

const useParentCategorization = (
  categoryAnnotation: DocumentCategoryAnnotation | null,
  categorization: ICategorization,
  documentId?: string,
  groupBlockId?: string,
  index?: number
) => {
  const { t } = useTranslation("annotationSideControls", {
    keyPrefix: "linkedParentCategorization",
  });

  const parentCategorization = categorization.search.parentCategorization;

  const parentCategoryAnnotation = useAppSelector((state) =>
    selectDocumentCategoryAnnotationId(state, {
      documentId: documentId,
      categorizationId: parentCategorization?.id,
      groupBlockId: groupBlockId,
      index: index,
    })
  );

  const categorizations = useAppSelector((state) =>
    selectCategorizations(state, { withConfig: false })
  ) as Array<ICategorization>;

  const {
    i18n: { language },
  } = useTranslation();

  const parentCategorizationValue = useMemo(() => {
    const parentCategoryValue = parentCategoryAnnotation?.categoryValue;
    if (!parentCategoryValue) {
      return null;
    }

    const { separator = null, index = null } =
      parentCategorization?.valueExtractor || {};
    if (!separator || index === null) {
      return parentCategoryValue.trim();
    }

    const splittedValue = parentCategoryValue.split(separator);
    if (splittedValue.length <= index) {
      return null;
    }

    return splittedValue[index].trim();
  }, [parentCategorization, parentCategoryAnnotation]);

  const isCategorizationDisabledByParent = useMemo(() => {
    return (
      parentCategorization?.disableIfNoParentValue &&
      !parentCategoryAnnotation?.categoryValue &&
      !categoryAnnotation
    );
  }, [
    parentCategoryAnnotation?.categoryValue,
    parentCategorization?.disableIfNoParentValue,
    categoryAnnotation,
  ]);

  const linkedParentLabel = useMemo(() => {
    if (!parentCategorization) {
      return null;
    }

    const parent = categorizations.find(
      (c) => c.id === parentCategorization.id
    );
    if (!parent) {
      return null;
    }

    return getInLanguageOrDefault(parent.label, language);
  }, [parentCategorization, categorizations, language]);

  const disabledParentTooltip = useMemo(() => {
    return t("disabledByNoParentValue").replace(
      "$PARENT",
      linkedParentLabel?.toUpperCase() ?? ""
    );
  }, [linkedParentLabel, t]);

  return {
    parentCategorizationValue,
    isCategorizationDisabledByParent,
    disabledParentTooltip,
  };
};

export default useParentCategorization;
