import { useAppSelector } from "../../app";
import inputNamesSelector from "../../documentTypes/selectors/inputNamesSelector";
import { useMemo } from "react";
import lodash from "lodash";
import { SOURCE_COLORS } from "../constants";
import {
  GraphLineMetrics,
  GraphPieMetrics,
} from "../interfaces/documentSetMetrics";

const useGraphConfig = (
  metrics: Array<GraphLineMetrics | GraphPieMetrics> | undefined
) => {
  const inputNames = useAppSelector(inputNamesSelector);
  const isBySource = useAppSelector(
    (state) => state.dataRefiningReducer.metricsFilter.isBySource
  );

  const seriesNames = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return lodash
      .chain(metrics)
      .map("name")
      .uniq()
      .sortBy((name) => name.toLowerCase())
      .value();
  }, [metrics]);

  const colors = useMemo(() => {
    if (isBySource) {
      const colors = inputNames
        .map((input, index) => {
          if (
            seriesNames
              .map((sn) => sn.toLowerCase())
              .includes(input.displayName.toLowerCase())
          ) {
            return input.color;
          }

          return null;
        })
        .filter((color): color is string => color !== null);

      if (colors.length < seriesNames.length) {
        const missingColorsCount = seriesNames.length - colors.length;
        const additionalColors = SOURCE_COLORS.slice(-missingColorsCount);

        colors.push(...additionalColors);
      }

      return colors;
    }

    return SOURCE_COLORS;
  }, [inputNames, seriesNames, isBySource]);

  return { seriesNames, colors };
};

export default useGraphConfig;
