import React, { FC } from "react";
import "./InteractiveLinePieGraph.scss";
import {
  GraphLineMetrics,
  GraphPieMetrics,
} from "../../interfaces/documentSetMetrics";
import LineGraph from "./lineGraph/LineGraph";
import PieGraph from "./pieGraph/PieGraph";
import useInteractiveLinePieGraph from "../../hooks/useInteractiveLinePieGraph";

type Props = {
  title: string;
  lineGraphMetrics: Array<GraphLineMetrics> | undefined;
  pieGraphMetrics: Array<GraphPieMetrics> | undefined;
  loading: boolean;
  useSourceColors: boolean;
  tooltipTitle: string;
};

const InteractiveLinePieGraph: FC<Props> = ({
  title,
  lineGraphMetrics,
  pieGraphMetrics,
  loading,
  tooltipTitle,
}) => {
  const {
    lineGraphRef,
    pieGraphRef,
    handleLineLegendClick,
    handlePieElementMouseOver,
    handlePieElementMouseLeave,
  } = useInteractiveLinePieGraph();

  return (
    <div className="metric-graphs-row">
      <div className="line-graph">
        <LineGraph
          title={title}
          metrics={lineGraphMetrics}
          loading={loading}
          lineGraphRef={lineGraphRef}
          handleLegendClick={handleLineLegendClick}
          tooltipTitle={tooltipTitle}
        />
      </div>
      <div className="pie-graph">
        <PieGraph
          metrics={pieGraphMetrics}
          loading={loading}
          pieGraphRef={pieGraphRef}
          handleElementMouseOver={handlePieElementMouseOver}
          handleElementMouseLeave={handlePieElementMouseLeave}
        />
      </div>
    </div>
  );
};

export default InteractiveLinePieGraph;
