import { useCallback, useRef } from "react";
import { calculateTotalMetrics } from "../utils/utils";
import { GraphPieMetrics } from "../interfaces/documentSetMetrics";
import { GraphEvent } from "../interfaces/graph";

const useInteractiveLinePieGraph = () => {
  // Library @opd/g2plot-react uses really complex types for their ref's so that's why we are using "any" type hinting for now.
  const lineGraphRef = useRef<any>(null);
  const pieGraphRef = useRef<any>(null);

  const handleLineLegendClick = useCallback(
    (e: GraphEvent) => {
      const filteredPieMetrics = calculateTotalMetrics(e.view?.filteredData);
      pieGraphRef.current.chart.changeData(filteredPieMetrics);
    },
    [pieGraphRef]
  );

  const handlePieElementMouseOver = useCallback(
    (e: GraphEvent) => {
      const eventData = e.data;
      if (eventData?.data) {
        const name = eventData.data.name;
        const otherSources: Array<GraphPieMetrics> | undefined =
          eventData.data.otherSources;

        if (otherSources?.length) {
          const names = otherSources.map((s) => s.name);
          lineGraphRef?.current.setState(
            "selected",
            (metric: GraphPieMetrics) => names.includes(metric.name)
          );
          lineGraphRef?.current.setState(
            "selected",
            (metric: GraphPieMetrics) => !names.includes(metric.name),
            false
          );
        } else {
          lineGraphRef?.current.setState(
            "selected",
            (metric: GraphPieMetrics) => metric.name === name
          );
          lineGraphRef?.current.setState(
            "selected",
            (metric: GraphPieMetrics) => metric.name !== name,
            false
          );
        }
      }
    },
    [lineGraphRef]
  );

  const handlePieElementMouseLeave = useCallback(() => {
    lineGraphRef?.current.setState("selected", () => true, false);
  }, [lineGraphRef]);

  return {
    lineGraphRef,
    pieGraphRef,
    handleLineLegendClick,
    handlePieElementMouseOver,
    handlePieElementMouseLeave,
  };
};

export default useInteractiveLinePieGraph;
